/**
 * Layout
 * Please update any global style here!
 */

import { useEffect, useState } from 'react';
import { LaunchScreen } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { externalScriptObserver } from 'lib/dom-helpers';
import { accountKey } from 'modules/global-wrapper';
import { postPaymentMethods } from 'modules/payment-methods';
import { postSessionDetails, sessionIsLoading } from 'modules/session-details';
import Track, { OnePayLanding } from 'modules/tracking';
import { SEARCH_PARAMS } from 'utils/constants';
import CoreView from './core-view';

const CoreContainer = () => {
  const dispatch = useAppDispatch();

  const [isComponentLoading, setComponentLoading] = useState<boolean>(true);
  const globalTheme = localStorage.getItem(SEARCH_PARAMS.theme);

  const selectedSessionIsLoading = useAppSelector(sessionIsLoading);
  const selectedAccountKey = useAppSelector(accountKey);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(OnePayLanding, {});
      // Observe any dom modification
      externalScriptObserver.observe(document, { childList: true, subtree: true });
    }
  }, [selectedAccountKey]);

  /**
   * Updating the body style for Dark mode.
   * ChameleonThemeProvider isn't working as expected.
   *  */
  useEffect(() => {
    const { body } = document;
    if (body) {
      body.style.backgroundColor = globalTheme === 'dark' ? '#25282d' : '#fff';
      body.style.color = globalTheme === 'dark' ? '#fff' : '#25282d';
    }
  }, [globalTheme]);

  /**
   * Calling all necessary APIs before mounting the routed component
   */
  useEffect(() => {
    const getPaymentAndSessionDetails = async () => {
      await dispatch(postSessionDetails());
      await dispatch(postPaymentMethods());
      setComponentLoading(false);
    };
    if (selectedAccountKey) {
      getPaymentAndSessionDetails();
    }
  }, [dispatch, selectedAccountKey]);

  const isLoading = isComponentLoading || selectedSessionIsLoading;

  return <>{isLoading ? <LaunchScreen /> : <CoreView />}</>;
};

export default CoreContainer;
