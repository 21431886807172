import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { SongbirdWrapper } from 'lib/cardinal-songbird';
import { postProcessRenewal } from 'modules/cog';
import { showErrorSnack } from 'modules/error';
import { sessionId } from 'modules/global-wrapper';
import {
  sessionCurrencyCode,
  sessionProducts,
  sessionQuoteId,
  sessionSuccessRedirectUrl,
  sessionTotalAmount,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import Track, { PaynowSaveAndPay, Revenue } from 'modules/tracking';
import {
  addCcResp,
  postTransactionAddCcPay,
  postTransactionAddCcScaPay,
  transactionFingerprintingId,
  transactionsCcPrepareResp,
} from 'modules/transactions-cc';
import { postProcessQuote } from 'modules/ucs';
import {
  COPAS_PAYMENT_STATUS,
  CREDIT_CARD_DEFAULT,
  PAYMENT_METHODS_TYPES,
  PURCHASE_FLOW_QUOTE,
  PURCHASE_FLOW_RENEWALS,
  PURCHASE_FLOW_SUBSCRIPTION,
  SEARCH_PARAMS,
  TRACKING_EVENT_STATUS,
} from 'utils/constants';
import st from 'utils/shared-translations';
import { getProductAdminDataFromCookie, removeProductAdminDataFromCookie } from 'utils/ui-utils';

import './credit-card-add-pay-button.css';

interface CreditCardAddPayButtonProps {
  isSubmitting: boolean;
  isFormInvalid: boolean;
  flexFormToken: string;
  formValues: any;
  setFormValues: any;
  CardinalSongbird: SongbirdWrapper | undefined;
}

let isSubmit = true;
let isDisabled = false;

const CreditCardAddPayButton: FC<CreditCardAddPayButtonProps> = ({
  isSubmitting,
  isFormInvalid,
  flexFormToken,
  formValues,
  setFormValues,
  CardinalSongbird,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [otpJWT, setOtpJWT] = useState('');

  const selectedSessionId = useAppSelector(sessionId);

  const renewalDate = localStorage.getItem(SEARCH_PARAMS.renewalDate) || '';

  // Session details selectors
  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedQuoteTotalPrice = useAppSelector(sessionTotalAmount);
  const selectedQuoteKey = useAppSelector(sessionQuoteId);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedSessionCurrencyCode = useAppSelector(sessionCurrencyCode);
  const selectedSessionCancelUrl = useAppSelector(sesssionCancelRedirectUrl);

  const selectedFingerprintId = useAppSelector(transactionFingerprintingId);
  const selectedTransactionPrepareResp = useAppSelector(transactionsCcPrepareResp);
  const selectedAddCreditCardResp = useAppSelector(addCcResp);

  const productAdminData = getProductAdminDataFromCookie();

  /**
   * Second Payment save API if the OTP is submitted correctly.
   */
  useEffect(() => {
    if (otpJWT && selectedAddCreditCardResp.paymentMethodKey) {
      dispatch(
        postTransactionAddCcScaPay({
          cardinalJwt: otpJWT,
          setAsDefault: formValues[CREDIT_CARD_DEFAULT],
          referenceId: selectedTransactionPrepareResp.songbirdReferenceId || '',
          paymentKey: selectedAddCreditCardResp.paymentKey,
          paymentMethodKey: selectedAddCreditCardResp.paymentMethodKey,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          browserLanguage: navigator.language,
          browserUserAgent: navigator.userAgent,
        }),
      )
        .unwrap()
        .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
      setOtpJWT('');
    }
  }, [dispatch, formValues, otpJWT, selectedAddCreditCardResp, selectedTransactionPrepareResp]);

  const processQuote = useCallback(() => {
    const data = {
      quoteCode: selectedQuoteKey,
      payload: {
        grossAmount: selectedQuoteTotalPrice,
        sessionId: selectedSessionId,
        paymentType: PAYMENT_METHODS_TYPES.cc,
        ...(Object.keys(productAdminData).length > 0 && { productAdmin: productAdminData }),
      },
    };
    dispatch(postProcessQuote(data))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: selectedSessionCancelUrl.includes('subscriptions')
            ? PURCHASE_FLOW_SUBSCRIPTION
            : PURCHASE_FLOW_QUOTE,
          paymentMethod: PAYMENT_METHODS_TYPES.cc,
          quoteId: selectedQuoteKey,
        }),
      )
      .finally(() => Object.keys(productAdminData).length > 0 && removeProductAdminDataFromCookie());
    // eslint-disable-next-line
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedSessionCancelUrl,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  const processRenwal = useCallback(() => {
    const payload = {
      date: renewalDate,
      sessionId: selectedSessionId,
      amount: selectedQuoteTotalPrice.toString(),
    };
    dispatch(postProcessRenewal(payload))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: PURCHASE_FLOW_RENEWALS,
          paymentMethod: PAYMENT_METHODS_TYPES.cc,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    renewalDate,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  /**
   * Based on the response from 1st or 2nd payment Save API,
   * if payerAuth result is available then show 3DS challenge,
   * if Status is failed, show error message,
   * if paymentMethodKey is available then call process quote or renewal.
   */
  useEffect(() => {
    if (selectedAddCreditCardResp) {
      const { payerAuthEnrollmentResult, paymentMethodKey, status } = selectedAddCreditCardResp;
      if (payerAuthEnrollmentResult && CardinalSongbird) {
        const cardinalPayload = {
          AcsUrl: payerAuthEnrollmentResult.acsUrl,
          Payload: payerAuthEnrollmentResult.paReq,
        };
        const orderPayload = {
          OrderDetails: {
            TransactionId: payerAuthEnrollmentResult.authenticationTransactionId,
          },
        };
        CardinalSongbird.show3DSChallange(cardinalPayload, orderPayload);
      } else if (status === COPAS_PAYMENT_STATUS.FAILED) {
        Track(PaynowSaveAndPay, { status: TRACKING_EVENT_STATUS.copasFailure });
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      } else if (paymentMethodKey) {
        if (renewalDate) processRenwal();
        else processQuote();
      }
    }
  }, [
    dispatch,
    processQuote,
    processRenwal,
    selectedAddCreditCardResp,
    renewalDate,
    selectedSessionId,
    selectedSuccessRedirectUrl,
    CardinalSongbird,
  ]);

  /**
   * First payment method save API.
   */
  const call1stPaymentSaveAPI = () => {
    dispatch(
      postTransactionAddCcPay({
        fingerPrintSessionId: selectedFingerprintId,
        cardinalJwt: selectedTransactionPrepareResp.songbirdTokenString || '',
        flexResponse: flexFormToken,
        flexKeyId: selectedTransactionPrepareResp.keyId,
        setAsDefault: formValues[CREDIT_CARD_DEFAULT],
        referenceId: selectedTransactionPrepareResp.songbirdReferenceId || '',
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        browserLanguage: navigator.language,
        browserUserAgent: navigator.userAgent,
      }),
    )
      .unwrap()
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  /**
   * After OTP is submitted successfully, store it locally to be used in 2nd payment save API.
   */
  const handleOTPChallenge = (otpResp: any, jwt: string) => {
    if (otpResp.ErrorNumber === 0 && otpResp.ErrorDescription === 'Success') {
      // If user cancels the OTP challenge.
      if (otpResp.Payment?.ExtendedData?.ChallengeCancel === '01') {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      }
      setOtpJWT(jwt);
      return;
    }
    dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
  };

  /**
   * Once Cardinal is successfully triggered, it calls save payment method.
   */
  const triggerCardinal = () => {
    const parsedToken = JSON.parse(atob(flexFormToken.split('.')[1]));
    if (!CardinalSongbird) return;
    return CardinalSongbird.trigger(parsedToken.content.paymentInformation.card.number.bin)
      .then(call1stPaymentSaveAPI)
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  /**
   * Configure Cardinal then initalizes with sca token.
   * Cardinal can been initlized only once unless the browser is refreshed.
   * This is limitation from Cardinal side, since they don`t have a Cardinal session-destroy method.
   */
  const setupCardinal = () => {
    if (CardinalSongbird) {
      CardinalSongbird.configure()
        .then(() => {
          // Initialize Cardinal with SCA Token
          CardinalSongbird.initialize({
            referenceId: selectedTransactionPrepareResp.songbirdReferenceId,
            isScaEnabledForMid: selectedTransactionPrepareResp.isScaEnabledMid,
            tokenString: selectedTransactionPrepareResp.songbirdTokenString,
          });

          // Event listener after the cardinal is initiated. Triggers bin process.
          CardinalSongbird.addEventListener('payments.setupComplete', () => triggerCardinal());

          // Event listener after the OTP is submitted.
          CardinalSongbird.addEventListener('payments.validated', (otpResp, jwt) => handleOTPChallenge(otpResp, jwt));
        })
        .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
    }
  };

  // Once user submits the form
  if (isSubmit && flexFormToken && selectedTransactionPrepareResp) {
    Track(PaynowSaveAndPay, {
      status: TRACKING_EVENT_STATUS.click,
      isDefault: formValues?.[CREDIT_CARD_DEFAULT] || false,
    });
    isSubmit = false;
    isDisabled = true;
    if (selectedTransactionPrepareResp.isScaEnabledMid) {
      setupCardinal();
    } else {
      call1stPaymentSaveAPI();
    }
  }

  // Set default card handler
  const handleDefaultCard = (event: any) => {
    const { checked } = event.target;
    setFormValues({
      ...formValues,
      [CREDIT_CARD_DEFAULT]: checked,
    });
  };

  return (
    <section className="credit-card-add-pay-button">
      <Checkbox disabled={isSubmitting} onChange={handleDefaultCard}>
        {intl.formatMessage(st['payment.method.set.as.default'])}
      </Checkbox>
      <section className="credit-card-add-pay-button__cta">
        <Button variant="primary" type="submit" disabled={isFormInvalid || isDisabled} isLoading={isSubmitting}>
          {intl.formatMessage(st['credit.card.save.pay'])}
        </Button>
      </section>
    </section>
  );
};

export default CreditCardAddPayButton;
