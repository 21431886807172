import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { SongbirdWrapper } from 'lib/cardinal-songbird';
import loadSongbird from 'lib/cardinal-songbird';
import { postProcessRenewal } from 'modules/cog';
import { isProcessRenewalLoading } from 'modules/cog/cog-selector';
import { showErrorSnack } from 'modules/error';
import { sessionId } from 'modules/global-wrapper';
import { isDeletePaymentMethodLoading, isSetDefaultPaymentLoading } from 'modules/payment-methods';
import {
  sessionCurrencyCode,
  sessionProducts,
  sessionQuoteId,
  sessionTotalAmount,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import Track, { PaynowCTA, Revenue } from 'modules/tracking';
import {
  postTransactionPrepare,
  postTransactionUseCcPay,
  postTransactionUseCcScaPay,
  transactionFingerprintingId,
  transactionsCcIsLoading,
  transactionsCcPrepareResp,
  useCcPayResp,
} from 'modules/transactions-cc';
import { postProcessQuote } from 'modules/ucs';
import { isProcessQuoteLoading } from 'modules/ucs/ucs-selector';
import { CreditCard } from 'types/payment-method';
import { PrepareResp } from 'types/transactions-cc';
import {
  COPAS_PAYMENT_STATUS,
  PAYMENT_METHODS_TYPES,
  PURCHASE_FLOW_QUOTE,
  PURCHASE_FLOW_RENEWALS,
  PURCHASE_FLOW_SUBSCRIPTION,
  SEARCH_PARAMS,
} from 'utils/constants';
import st from 'utils/shared-translations';
import { getProductAdminDataFromCookie, removeProductAdminDataFromCookie } from 'utils/ui-utils';

interface CCBuyNowProps {
  currentCreditCard: CreditCard;
}

let CardinalSongbird: SongbirdWrapper | undefined = undefined;

const loadExternalScripts = async (prepareResp: PrepareResp) => {
  const { isScaEnabledMid, songbirdJavascriptSource, songbirdJavascriptIntegrity } = prepareResp;

  if (isScaEnabledMid) {
    CardinalSongbird = loadSongbird(songbirdJavascriptSource, songbirdJavascriptIntegrity);
  }
};

let isDisabled = false;

const CCBuyNow: FC<CCBuyNowProps> = ({ currentCreditCard }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [otpJWT, setOtpJWT] = useState('');
  const [isComponentLoading, setComponentLoading] = useState(false);

  const selectedSessionId = useAppSelector(sessionId);
  const selectedQuoteProcessIsLoading = useAppSelector(isProcessQuoteLoading);
  const selectedProcessRenewalIsLoading = useAppSelector(isProcessRenewalLoading);
  const renewalDate = localStorage.getItem(SEARCH_PARAMS.renewalDate) || '';

  // Session details selectors
  const selectedQuoteTotalPrice = useAppSelector(sessionTotalAmount);
  const selectedQuoteKey = useAppSelector(sessionQuoteId);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedSessionCurrencyCode = useAppSelector(sessionCurrencyCode);
  const selectedSessionCancelUrl = useAppSelector(sesssionCancelRedirectUrl);

  // Transaction selectors
  const selectedTransactionCcLoading = useAppSelector(transactionsCcIsLoading);
  const selectedTransactionPrepareResp = useAppSelector(transactionsCcPrepareResp);
  const selectedTransactionUseCcResp = useAppSelector(useCcPayResp);
  const selectedFingerprintId = useAppSelector(transactionFingerprintingId);

  const selectedDeletePaymentMethodIsLoading = useAppSelector(isDeletePaymentMethodLoading);
  const selectedSetDefaultPaymentIsLoading = useAppSelector(isSetDefaultPaymentLoading);

  const productAdminData = getProductAdminDataFromCookie();

  // Call prepare transaction once paymentMethodKey is available
  useEffect(() => {
    if (currentCreditCard.paymentMethodKey) {
      dispatch(postTransactionPrepare({ paymentMethodKey: currentCreditCard.paymentMethodKey }))
        .unwrap()
        .then((resp: any) => {
          loadExternalScripts(resp);
        })
        .catch(() => {
          setComponentLoading(false);
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        });
    }
  }, [currentCreditCard.paymentMethodKey, dispatch]);

  /**
   * Second Payment save API if the OTP is submitted correctly.
   */
  useEffect(() => {
    if (otpJWT && selectedTransactionUseCcResp.paymentKey) {
      dispatch(
        postTransactionUseCcScaPay({
          fingerPrintSessionId: selectedFingerprintId,
          cardinalJWT: otpJWT,
          referenceId: selectedTransactionPrepareResp.songbirdReferenceId || null,
          paymentKey: selectedTransactionUseCcResp.paymentKey,
          paymentMethodKey: selectedTransactionUseCcResp.paymentMethodKey,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          browserLanguage: navigator.language,
          browserUserAgent: navigator.userAgent,
        }),
      )
        .unwrap()
        .catch(() => {
          setComponentLoading(false);
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        });
      setOtpJWT('');
    }
  }, [
    dispatch,
    otpJWT,
    selectedFingerprintId,
    selectedTransactionPrepareResp.songbirdReferenceId,
    selectedTransactionUseCcResp,
  ]);

  const processQuote = useCallback(() => {
    const data = {
      quoteCode: selectedQuoteKey,
      payload: {
        grossAmount: selectedQuoteTotalPrice,
        sessionId: selectedSessionId,
        paymentType: PAYMENT_METHODS_TYPES.cc,
        ...(Object.keys(productAdminData).length > 0 && { productAdmin: productAdminData }),
      },
    };
    dispatch(postProcessQuote(data))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: selectedSessionCancelUrl.includes('subscriptions')
            ? PURCHASE_FLOW_SUBSCRIPTION
            : PURCHASE_FLOW_QUOTE,
          paymentMethod: PAYMENT_METHODS_TYPES.cc,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      )
      .finally(() => Object.keys(productAdminData).length > 0 && removeProductAdminDataFromCookie());
    // eslint-disable-next-line
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedSessionCancelUrl,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  const processRenwal = useCallback(() => {
    const payload = {
      date: renewalDate,
      sessionId: selectedSessionId,
      amount: selectedQuoteTotalPrice.toString(),
    };
    dispatch(postProcessRenewal(payload))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: PURCHASE_FLOW_RENEWALS,
          paymentMethod: PAYMENT_METHODS_TYPES.cc,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    renewalDate,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  /**
   * Based on the response from 1st or 2nd payment Save API,
   * if payerAuth result is available then show 3DS challenge,
   * if Status is failed, show error message,
   * if paymentMethodKey is available then call process quote or renewal.
   */
  useEffect(() => {
    if (selectedTransactionUseCcResp) {
      const { payerAuthEnrollmentResult, paymentMethodKey, status } = selectedTransactionUseCcResp;
      if (payerAuthEnrollmentResult && CardinalSongbird) {
        const cardinalPayload = {
          AcsUrl: payerAuthEnrollmentResult.acsUrl,
          Payload: payerAuthEnrollmentResult.paReq,
        };
        const orderPayload = {
          OrderDetails: {
            TransactionId: payerAuthEnrollmentResult.authenticationTransactionId,
          },
        };
        CardinalSongbird.show3DSChallange(cardinalPayload, orderPayload);
      } else if (status === COPAS_PAYMENT_STATUS.FAILED) {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      } else if (paymentMethodKey) {
        if (renewalDate) processRenwal();
        else processQuote();
      }
    }
  }, [selectedTransactionUseCcResp, selectedSessionId, processQuote, processRenwal, dispatch, renewalDate]);

  /**
   * After OTP is submitted successfully, store it locally to be used in 2nd payment save API.
   */
  const handleOTPChallenge = (otpResp: any, jwt: string) => {
    if (otpResp.ErrorNumber === 0 && otpResp.ErrorDescription === 'Success') {
      // If user cancels the OTP challenge.
      if (otpResp.Payment?.ExtendedData?.ChallengeCancel === '01') {
        setComponentLoading(false);
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      }
      setOtpJWT(jwt);
      return;
    }
    dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
    setComponentLoading(false);
  };

  // Call 1st useCCPay API on click of Buy Now
  const payUsingCreditCard = () => {
    dispatch(
      postTransactionUseCcPay({
        fingerPrintSessionId: selectedFingerprintId,
        referenceId: selectedTransactionPrepareResp.songbirdReferenceId || null,
        paymentMethodKey: currentCreditCard.paymentMethodKey,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        browserLanguage: navigator.language,
        browserUserAgent: navigator.userAgent,
      }),
    )
      .unwrap()
      .catch(() => {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        setComponentLoading(false);
      });
  };

  /**
   * Configure Cardinal then initalizes with sca token.
   * Cardinal can been initlized only once unless the browser is refreshed.
   * This is limitation from Cardinal side, since they don`t have a Cardinal session-destroy method.
   */
  const setupCardinal = () => {
    if (CardinalSongbird) {
      CardinalSongbird.configure()
        .then(() => {
          // Initialize Cardinal with SCA Token
          CardinalSongbird?.initialize({
            referenceId: selectedTransactionPrepareResp.songbirdReferenceId,
            isScaEnabledForMid: selectedTransactionPrepareResp.isScaEnabledMid,
            tokenString: selectedTransactionPrepareResp.songbirdTokenString,
          });

          CardinalSongbird?.addEventListener('payments.setupComplete', () => payUsingCreditCard());

          // Event listener after the OTP is submitted.
          CardinalSongbird?.addEventListener('payments.validated', (otpResp, jwt) => handleOTPChallenge(otpResp, jwt));
        })
        .catch(() => {
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
          setComponentLoading(false);
        });
    }
  };

  // Pay now button click handler
  const payNow = () => {
    isDisabled = true;
    Track(PaynowCTA, {
      paymentType: PAYMENT_METHODS_TYPES.cc,
      paymentMethodKey: currentCreditCard.paymentMethodKey.slice(-5),
    });
    setComponentLoading(true);
    if (currentCreditCard.isScaEnabled) {
      setupCardinal();
    } else {
      payUsingCreditCard();
    }
  };

  const isButtonDisabled =
    isDisabled ||
    selectedDeletePaymentMethodIsLoading ||
    selectedSetDefaultPaymentIsLoading ||
    !currentCreditCard.paymentMethodKey;

  const isButtonLoading =
    isComponentLoading ||
    selectedTransactionCcLoading ||
    selectedQuoteProcessIsLoading ||
    selectedProcessRenewalIsLoading;

  return (
    <Button isLoading={isButtonLoading} disabled={isButtonDisabled} size="large" fullWidth onClick={payNow}>
      {intl.formatMessage(st['pay.now.cta'])}
    </Button>
  );
};

export default CCBuyNow;
