import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AlertOutlinedIcon,
  CheckmarkCircleOutlinedIcon,
  GotoAdminPartnerFaviconIcon,
} from '@getgo/chameleon-icons/react';
import { Button, ButtonLink, CircularProgress, GotoLogo, Typography } from '@getgo/chameleon-web-react-wrapper';

import config from 'config';
import { useAppSelector } from 'hooks';
import { isProcessRenewalFailed, isProcessRenewalSuccessful } from 'modules/cog/cog-selector';
import { accountKey } from 'modules/global-wrapper';
import {
  isEcomClient,
  sessionEmail,
  sessionProducts,
  sessionSuccessRedirectUrl,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import { isProcessQuoteFailed, isProcessQuoteLoading, isProcessQuoteSuccessful } from 'modules/ucs/ucs-selector';
import { classicProductFamilyKey, PARTNER_TYPES, SEARCH_PARAMS } from 'utils/constants';
import st from 'utils/shared-translations';

import './payment-feedback.css';

const PaymentFeedback: FC = (): JSX.Element => {
  const intl = useIntl();

  const partnerType = localStorage.getItem(SEARCH_PARAMS.partnerType);

  const selectedAccountKey = useAppSelector(accountKey);

  const selectedUserEmail = useAppSelector(sessionEmail);
  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);
  const selectedQuotePriceList = useAppSelector(sessionProducts);
  const selectedClienIsEcom = useAppSelector(isEcomClient);

  const selectedQuoteProcessIsLoading = useAppSelector(isProcessQuoteLoading);
  const selectedIsProcessQuoteSucceed = useAppSelector(isProcessQuoteSuccessful);
  const selectedIsProcesssQuoteFailed = useAppSelector(isProcessQuoteFailed);

  const selectedIsProcessRenewalFailed = useAppSelector(isProcessRenewalFailed);
  const selectedIsProcessRenewalSucceed = useAppSelector(isProcessRenewalSuccessful);

  const isClassicProductFamily = selectedQuotePriceList.some((item) =>
    classicProductFamilyKey.includes(item?.productGroup),
  );

  const goToSuccessURL = () => {
    localStorage.clear();
    window.location.assign(selectedSuccessRedirectUrl);
  };

  const goToCheckout = () => {
    localStorage.clear();
    window.location.assign(selectedCancelRedirectUrl);
  };

  const hasGrantPartnerAccessPrivilege =
    selectedCancelRedirectUrl.includes('quotes') && partnerType?.toUpperCase() === PARTNER_TYPES.child;

  let feedbackType = '';

  if (selectedQuoteProcessIsLoading) {
    feedbackType = 'loading';
  } else if (selectedIsProcessQuoteSucceed || selectedIsProcessRenewalSucceed) {
    feedbackType = 'success';
  } else if (selectedIsProcesssQuoteFailed || selectedIsProcessRenewalFailed) {
    feedbackType = 'failure';
  }

  return (
    <>
      {feedbackType ? (
        <div className="payment-feedback">
          <GotoLogo className="payment-feedback__logo">
            <GotoAdminPartnerFaviconIcon />
          </GotoLogo>
          {
            {
              loading: (
                <div className="payment-feedback__loader">
                  <CircularProgress />
                  <Typography tag="h1" variant="heading-small" color="type-color-default">
                    {intl.formatMessage(st['payment.in.progress.loader.message.header'])}
                  </Typography>
                  <Typography tag="p" variant="body-small" color="type-color-secondary">
                    {intl.formatMessage(st['payment.in.progress.loader.message.body'])}
                  </Typography>
                </div>
              ),
              success: (
                <div className="payment-feedback__succeed">
                  <CheckmarkCircleOutlinedIcon size="80px" color="var(--goto-icon-color-success)" />
                  <Typography tag="h1" variant="heading-small" className="feedback__body-info" gutterBottom>
                    {intl.formatMessage(st['feedback.success.order.purchase.successful'])}
                  </Typography>
                  <Typography tag="p" variant="body-small" color="type-color-secondary">
                    <FormattedMessage
                      {...st['payment.succeed.feedback.message']}
                      values={{
                        email: selectedUserEmail,
                      }}
                    />
                  </Typography>
                  <div className="feedback__ctas">
                    {isClassicProductFamily && !hasGrantPartnerAccessPrivilege && !selectedClienIsEcom && (
                      <ButtonLink variant="tertiary" size="medium" href={config.CLASSIC_PRODUCTS_URL}>
                        {intl.formatMessage(st['feedback.success.to.product'])}
                      </ButtonLink>
                    )}
                    {hasGrantPartnerAccessPrivilege && (
                      <ButtonLink
                        variant="tertiary"
                        size="medium"
                        href={`${config.ADMIN_URL}/${selectedAccountKey}/settings/partner`}
                        onClick={() => localStorage.clear()}
                      >
                        {intl.formatMessage(st['feedback.success.partner.grant.cta'])}
                      </ButtonLink>
                    )}
                    <Button size="medium" variant="primary" onClick={goToSuccessURL}>
                      {intl.formatMessage(
                        st[
                          selectedClienIsEcom
                            ? 'feedback.success.cta.continue'
                            : 'feedback.success.back.to.subscriptions'
                        ],
                      )}
                    </Button>
                  </div>
                </div>
              ),
              failure: (
                <div className="payment-feedback__failure">
                  <AlertOutlinedIcon size="50px" color="#d33a61" />
                  <Typography tag="h3" variant="body-medium-strong" gutterBottom>
                    {intl.formatMessage(st['feedback.failure.payment.process'])}
                  </Typography>
                  <Typography tag="p" variant="body-small">
                    <FormattedMessage
                      {...st['feedback.failure.payment.info']}
                      values={{
                        link: (msg: ReactNode) => (
                          <a href="https://www.goto.com/learn-more" target="_blank" rel="noopener noreferrer">
                            {msg}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                  <Button fullWidth size="medium" variant="primary" onClick={goToCheckout}>
                    {intl.formatMessage(st['back.to.previous.page'])}
                  </Button>
                </div>
              ),
            }[feedbackType]
          }

          <footer className="payment-feedback__footer">
            <Typography variant="caption-medium" color="type-color-secondary">
              <FormattedMessage
                {...st['payment.tnc']}
                values={{
                  'tos-link': (msg: ReactNode) => (
                    <a
                      href="https://www.goto.com/company/legal/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography tag="span" variant="body-small" color="link">
                        {msg}
                      </Typography>
                    </a>
                  ),
                  'privacy-policy': (msg: ReactNode) => (
                    <a href="https://www.goto.com/company/legal/privacy" target="_blank" rel="noopener noreferrer">
                      <Typography tag="span" variant="body-small" color="link">
                        {msg}
                      </Typography>
                    </a>
                  ),
                  'anti-spam': (msg: ReactNode) => (
                    <a
                      href="https://www.goto.com/company/legal/anti-spam-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography tag="span" variant="body-small" color="link">
                        {msg}
                      </Typography>
                    </a>
                  ),
                }}
              />
            </Typography>
          </footer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentFeedback;
